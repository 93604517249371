import React from 'react'

import LinkButton from 'shared/Button/LinkButton'

const s = {
  container: `w-full h-fit`,
  list: `flex flexbetween flex-wrap list-none p-0`,
  items: `p-0`,
  button: `py-4`,
  page: (show: any) => `
  ${show ? `text-white bg-black` : `text-black bg-transparent`}
  p-3 mx-1 border-0 border-solid border-black hover:bg-yellow transition-all delay-300 ease-out duration-300
  `,
}

const Paginations = ({ page }: any) => {
  const { firstPage, lastPage, prevPage, nextPage, currentPage, countPages } =
    page

  return (
    <section className={s.container}>
      <ul className={s.list}>
        <li className={s.items}>
          {!firstPage && (
            <LinkButton classname={s.button} to={`/posts/${prevPage}`}>
              Prev
            </LinkButton>
          )}
        </li>

        <li className={s.items}>
          {Array.from({ length: countPages }, (_, id) => (
            <LinkButton
              classname={s.page(id + 1 === currentPage)}
              to={`/posts/${id === 0 ? '' : id + 1}`}
              key={id + 1}
            >
              {id + 1}
            </LinkButton>
          ))}
        </li>

        <li className={s.items}>
          {!lastPage && (
            <LinkButton classname={s.button} to={`/posts/${nextPage}`}>
              Next
            </LinkButton>
          )}
        </li>
      </ul>
    </section>
  )
}

export default Paginations
