import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Markdown from 'markdown-to-jsx'

import SEO from 'shared/Seo/Seo'
import Layout from 'shared/Layout/Layout'
import Paginations from 'shared/Pagination/Paginations'
import Random from 'main/Post/Random'
import LinkButton from 'shared/Button/LinkButton'

interface Datas {
  slug: string
  pageContext: any
  data: {
    allMarkdownRemark: {
      nodes: {
        frontmatter: {
          posts: {
            id: number
            slug: string
            title: string
            date: string
            snippet: string
            image: {
              alt: string
              src: any
            }
          }
        }
      }[]
    }
  }
}

const s = {
  container: `w-full h-fit`,
  wrapper: `mt-60 mx-0 mb-40 wrapper`,
  title: `-mt-4 mb-6`,

  grid: `grid-half gap-32 mdx:grid-cols-1 mdx:gap-36`,
  grid2: `grid-2 gap-20 mb-28 mdx:grid-1 mdx:gap-20 `,
  content: `flex flex-col`,

  card: `flex flex-col`,

  link: `w-full h-h45 2xlm:h-h40 xlm:h-h35 lgm:h-h25 xsx:h-h32`,
  image: `w-full h-full `,

  body: `flex flex-col pt-8`,
  subtitle: `-mt-2 -mb-2 text-clamp-3xl`,
  date: `mb-6 text-sm`,
  snippet: `mb-12 text-justify post`,
  button: `block ml-auto`,
}

const Posts = ({ pageContext, data }: Datas) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <section className={s.container}>
      <SEO title={`All posts`} description={`More bite posts page...`} />
      <Layout backgrounds="bg-black">
        <div className={s.wrapper}>
          <div className={s.grid}>
            <div className={s.content}>
              <h2 className={s.title}>All News</h2>

              <div className={s.grid2}>
                {posts.map(
                  ({
                    frontmatter: {
                      posts: { id, slug, title, date, snippet, image },
                    },
                  }) => (
                    <div className={s.card} key={id}>
                      <Link className={s.link} to={`/post/${slug}/`}>
                        <GatsbyImage
                          className={s.image}
                          image={image.src.childImageSharp.gatsbyImageData}
                          alt={image.alt}
                          loading="eager"
                        />
                      </Link>

                      <div className={s.body}>
                        <h3 className={s.subtitle}>{title}</h3>
                        <p className={s.date}>{date}</p>

                        <Markdown
                          className={s.snippet}
                        >{`${snippet}...`}</Markdown>

                        <LinkButton classname={s.button} to={`/post/${slug}/`}>
                          Read News
                        </LinkButton>
                      </div>
                    </div>
                  )
                )}
              </div>
              <Paginations page={pageContext} />
            </div>
            <Random />
          </div>
        </div>
      </Layout>
    </section>
  )
}

export default Posts

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      filter: { fileAbsolutePath: { glob: "**/src/data/posts/*.md" } }
      sort: { fields: frontmatter___posts___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          posts {
            id
            slug
            title
            date
            snippet
            image {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 80
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
